import Component from '../../../../../libs/components/component';
import { getRegister } from '../../../../../libs/register';
import { openLoader, closeLoader, checkFormWithoutValidation } from '../../../../../libs/utils';
import { getInsurancesManager } from '../../../../../libs/insurances-manager';
import { getApiProvider } from '../../../../../libs/api-provider';
import { getFlowManager } from '../../../../../libs/flow-manager';
import * as tableTemplate from './partials/table.html';
import { runTemplate } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { formToJSON } from '../../../../../libs/form-to-json';
import { getTrackingManager } from '../../../../../libs/tracking-manager'; 
import './style.scss';

export default class AssicurazioniForm extends Component {
    constructor(name, root) {
        super(name, root);
        this.form = this._dEl('form');

        this.taxIdCodeField = this._dEl('taxIdCode');
        this.fidelityCard = this._dEl('fidelityCard');
        this.consentField = this._dEl('consent');
        this.regulationField = this._dEl('regulation');
        this.submitButton = this._dEl('submit');

        this.tableWrapper = this._dEl('tableWrapper');
        this.loginPageUrl = this.root.dataset.loginLink;

        this.receiptValidityDays = this.root.dataset.receiptValidityDays;
        this.afterPolicyActivationPageUrl = this.root.dataset.afterPolicyActivationPageUrl;
        this.insurancesInformation;
        this.user;

        this.mobilePhone = this._dEl('mobilePhone');
        this.mobilePhoneUnderLabel = this._dEl('mobilePhoneUnderLabel');

        this.tableLabels = {
            data: this.root.dataset.tableLabelDate,
            puntoVendita: this.root.dataset.tableLabelShop,
            totaleSpesa: this.root.dataset.tableLabelTotalCost,
            stato: this.root.dataset.tableLabelStatus,
        };

        this.flowManager = getFlowManager();
        this.apiProvider = getApiProvider();
        this.insurancesManager = getInsurancesManager();
        this.register = getRegister();
        this.trackingManager = getTrackingManager(); 

        this._doLogic();
    }

    async _doLogic() {
        if (!window.wcmmodeDisabled) {
            this.root.classList.remove('hidden');
            return;
        }

        openLoader('main');

        // get status of user (user, scontrini, insurance products)
        this.insurancesInformation = await this.insurancesManager.getInsurancesInformation();
        this.user = this.insurancesInformation.user;
        if (this.insurancesInformation.status === 'guest') {
            if (this.loginPageUrl) {
                const redirectUrl = new URL(this.loginPageUrl);
                redirectUrl.searchParams.set('cb', window.location);
                window.location = redirectUrl.href;
            } else {
                closeLoader('main');
            }
            return;
        }

        if (this.insurancesInformation.status === 'light') {
            this.flowManager.startFlow({
                flowName: 'card-error',
                flowSteps: [{ name: 'insurances-error' }],
                initialData: {
                    errorType: 'noCardError',
                },
            });
            closeLoader('main');
            return;
        }

        if (this.insurancesInformation.status === 'policyAlreadyActive') {
            this.flowManager.startFlow({
                flowName: 'insurance-error',
                flowSteps: [{ name: 'insurances-error' }],
                initialData: {
                    errorType: 'insurancePolicyAlreadyActiveError',
                },
            });
            this.trackingManager.track(this.root, {
                event: 'pageview',
                funnel: {
                    nomeFunnel: 'Carrello protetto',
                    stepFunnel: 'Modale errore polizza gia attiva'
                } 
            });  
            
            closeLoader('main');
            return;
        }

        // scontrini (deve essere nel range di validità e deve avere valore >= 30)
        const scontriniError =
            this.insurancesInformation.status === 'noReceipts' ||
            this._getScontrinoValidity(this.insurancesInformation.scontrino) <= -1 ||
            this.insurancesInformation.scontrino.total < 30;

        if (scontriniError) {
            this.flowManager.startFlow({
                flowName: 'scontrini-error',
                flowSteps: [{ name: 'insurances-error' }],
                initialData: {
                    errorType: 'noValidReceiptsError',
                },
            });
            this.trackingManager.track(this.root, {
                event: 'pageview',
                funnel: {
                    nomeFunnel: 'Carrello protetto',
                    stepFunnel: 'Modale errore scontrino'
                }
            }); 
            closeLoader('main');
            return;
        }

        // controllo età
        if (!this._checkRegistry()) {
            this.flowManager.startFlow({
                flowName: 'registry-error',
                flowSteps: [{ name: 'insurances-error' }],
                initialData: {
                    errorType: 'registryError',
                },
            });
            this.trackingManager.track(this.root, {
                event: 'pageview',
	            funnel: {
                    nomeFunnel: 'Carrello protetto',
                    stepFunnel: 'Modale errore eta'
                }
            }); 
            closeLoader('main');
            return;
        }

        // populate table
        this._prepareTable(this.insurancesInformation.scontrino);

        this._populateUserForm();
        this._addEventListeners();
        this.root.classList.remove('hidden');

        this.trackingManager.track(this.root, {
            event: 'pageview',
            funnel: {
                nomeFunnel: 'Carrello protetto',
                stepFunnel: 'Form sottoscrizione'
 
            } 
        }); 
        closeLoader('main');
    }

    _checkMobilePhoneLabel() {
        if (!this.mobilePhoneUnderLabel) return;

        const mbObj = this.register.getClass(this.mobilePhone);
        if (mbObj.checkValidity()) {
            this.mobilePhoneUnderLabel.classList.remove('!mt-5');
        } else {
            this.mobilePhoneUnderLabel.classList.add('!mt-5');
        }
    }

    _addEventListeners() {
        this._addListener(
            'rcInputChanged',
            () => {
                if (this._getFormValidity()) {
                    this._activateButton();
                } else {
                    this._deactivateButton();
                }
            },
            this.form
        );

        this._addListener(
            'focusout',
            () => {
                this._checkMobilePhoneLabel();
            },
            this.mobilePhone.querySelector('input')
        );

        this._addListener(
            'rcInputChanged',
            () => {
                this._checkMobilePhoneLabel();
            },
            this.mobilePhone
        );

        this._addListener(
            'submit',
            async (e) => {
                e.preventDefault();
                await this._submitForm();
            },
            this.form
        );
    }

    _prepareTable(data) {
        let scontrinoData;
        if (data) {
            scontrinoData = this._processRow(data);
        }
        const tableRendered = runTemplate(tableTemplate, { tableLabels: this.tableLabels, scontrino: scontrinoData });
        if (tableRendered) {
            this.tableWrapper.append(tableRendered);
        }
    }

    _processRow(row) {
        const diffDays = this._getScontrinoValidity(row);
        let status;
        if (diffDays == 1) {
            status = 'Valido per ' + diffDays + ' giorno';
        }
        if (diffDays == 0) {
            status = 'Valido fino ad oggi';
        }
        if (diffDays > 1) {
            status = 'Valido per ' + diffDays + ' giorni';
        }
        let date = new Date(row.date);
        let formattedDate = this._formatDate(date);
        let total = (Math.round(row.total * 100) / 100).toFixed(2);
        total = total.replace(/[,.]/g, function (m) {
            return m === ',' ? '.' : ',';
        });
        return {
            data: formattedDate,
            puntoVendita: row.store,
            totaleSpesa: total,
            stato: status,
        };
    }

    _formatDate(date) {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1;
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    }

    _populateUserForm() {
        let nameField = this.form.elements['name'];
        if (nameField) {
            let parentDivName = nameField.closest('.rt008-text-field');
            parentDivName.classList.add('rt008-text-field--locked');
            nameField.value = this.user.nome;
            nameField.disabled = true;
        }
        let surnameField = this.form.elements['surname'];
        if (surnameField) {
            let parentDivSurname = surnameField.closest('.rt008-text-field');
            parentDivSurname.classList.add('rt008-text-field--locked');
            surnameField.value = this.user.cognome;
            surnameField.disabled = true;
        }
        let emailField = this.form.elements['email'];
        if (emailField) {
            let parentDivSurname = emailField.closest('.rt008-text-field');
            parentDivSurname.classList.add('rt008-text-field--locked');
            emailField.value = this.user.email;
            emailField.disabled = true;
        }
        if (this.user.dataNascita) {
            let birthDateField = this.form.elements['birthDate'];
            if (birthDateField) {
                let parentDivBirthDate = birthDateField.closest('.rt009-date-picker');
                parentDivBirthDate.classList.add('rt009-date-picker--locked');
                birthDateField.value = this.user.dataNascita;
                birthDateField.disabled = true;
            }
        }
        if (this.user.comuneNascita) {
            let birthCityField = this.form.elements['birthCity'];
            if (birthCityField) {
                let parentDivBirthCity = birthCityField.closest('.rt008-text-field');
                parentDivBirthCity.classList.add('rt008-text-field--locked');
                birthCityField.classList.add('capitalize');
                birthCityField.value = this.user.comuneNascita;
                birthCityField.disabled = true;
            }
        }
        if (this.user.telefonoCellulare) {
            let telField = this.form.elements['mobilePhone'];
            if (telField) {
                let parentDivTel = telField.closest('.rt008-text-field');
                parentDivTel.classList.add('rt008-text-field--locked');
                telField.value = this.user.telefonoCellulare;
                telField.disabled = true;
            }
        } else {
            let telField = this.form.elements['mobilePhone'];
            if (telField) {
                let parentDivTel = telField.closest('.rt008-text-field');
                parentDivTel.classList.remove('rt008-text-field--locked');
                parentDivTel.classList.add('rt008-text-field--validEnabled');
                telField.disabled = false;
            }
        }
        if (this.user.cartaFedelta) {
            let fidelityCardField = this.form.elements['fidelityCard'];
            if (fidelityCardField) {
                let parentDivFidelityCard = fidelityCardField.closest('.rt008-text-field');
                parentDivFidelityCard.classList.add('rt008-text-field--locked');
                fidelityCardField.value = this.user.cartaFedelta;
                fidelityCardField.disabled = true;
            }
        }
        if (this.user.codiceFiscale) {
            let codiceFiscaleField = this.form.elements['taxIdCode'];
            if (codiceFiscaleField) {
                codiceFiscaleField.value = this.user.codiceFiscale;
            }
        }
        if (this.user.indirizzo && this.user.numeroCivico) {
            let addressField = this.form.elements['address'];
            if (addressField) {
                addressField.value = this.user.indirizzo + ' ' + this.user.numeroCivico;
            }
        }
        if (this.user.cap) {
            let postalCodeField = this.form.elements['postalCode'];
            if (postalCodeField) {
                postalCodeField.value = this.user.cap;
            }
        }
        if (this.user.citta) {
            let cityField = this.form.elements['city'];
            if (cityField) {
                cityField.classList.add('capitalize');
                cityField.value = this.user.citta;
            }
        }
    }

    _getFormValidity() {
        return checkFormWithoutValidation(this.form);
    }

    _activateButton() {
        if (this.submitButton.hasAttribute('disabled')) {
            this.submitButton.removeAttribute('disabled');
        }
    }

    _deactivateButton() {
        if (!this.submitButton.hasAttribute('disabled')) {
            this.submitButton.setAttribute('disabled', '');
        }
    }

    async _submitForm() {
        openLoader('main');
        const url = new URL(this.afterPolicyActivationPageUrl, window.origin);
        try {
            let jsonForm = formToJSON(this.form);
            jsonForm.receiptDate = new Date(this.insurancesInformation.scontrino?.date).toISOString();
            jsonForm.receiptAmount = this.insurancesInformation.scontrino?.total;
            jsonForm.receiptPdv = this.insurancesInformation.scontrino?.anacanId;
            jsonForm.birthDate = this._formatBirthdate();
            const requestDate = new Date().toISOString();
            jsonForm.requestDate = requestDate;
            jsonForm.effectiveDate = requestDate;

            await this.apiProvider.policyActivation(jsonForm);
            if (!this.afterPolicyActivationPageUrl) {
                console.warn('Missing after policy activation page url');
                return;
            }

            url.searchParams.set('status', 'success');
            window.location = url.href;
        } catch (error) {
            if (error?.code === 'BACKEND:CONFLICT_STATE') {
                url.searchParams.set('status', 'policyAlreadyActiveError');
            } else {
                url.searchParams.set('status', 'genericError');
            }
            console.warn(error || 'Generic error from backend');
        } finally {
            window.location = url.href;
            closeLoader('main');
        }
    }

    _checkRegistry() {
        const age = this._getAge();
        return 18 <= age && age <= 79;
    }

    _getAge() {
        if(!this.user.dataNascita) return 0;
        let ddMMYYYY = this.user.dataNascita.split('-');
        let ageDifMs = Date.now() - new Date(ddMMYYYY[2], Number(ddMMYYYY[1]) - 1, ddMMYYYY[0]);
        let ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    _formatBirthdate() {
        let ddMMYYYY = this.user.dataNascita.split('-');
        return new Date(ddMMYYYY[2], Number(ddMMYYYY[1]) - 1, ddMMYYYY[0], 12, 0).toISOString();
    }

    _getScontrinoValidity(scontrino) {
        return Math.floor(
            Math.abs(
                new Date(scontrino.date).setUTCHours(0, 0, 0, 0) +
                (Number(this.receiptValidityDays) + 1) * (1000 * 60 * 60 * 24) -
                new Date()
            ) /
            (1000 * 60 * 60 * 24)
        );
    }
}


