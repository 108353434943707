import './style.scss';
import Component from '../../../../../libs/components/component';
import { BREAKPOINTS } from '../../../../../libs/constants';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper';
import { shuffleCards } from '../../../../../libs/utils';

export default class Hero extends Component {
    constructor(name, root) {
        super(name, root);
        this.randomizeCards = root.dataset.randomizeCards;
        this.sliderAutoplayDelay = Number(root.dataset.sliderAutoplayDelay); 
        this.carousel = this._dEl('carousel');
        this.pagination = this._dEl('pagination');
        this.swiper = null;
        this.slide = this._dEl('slideContainer');
        this.track = this._dEl('track');
        this.level = this._dEl('levelLabel');
        if (this.level != null) {
            this.icons = this._dEl('sumIconChef', true);
            this.chef2 = this.icons[1];
            this.chef3 = this.icons[2];
        }

        this._init();
    }

    async _init() {
        if (this.randomizeCards == 'true') {
            this._randomizeCards();
        }
        await this._initSwiper();
        this._initIcons();
        this._addEventListeners();
        this._updatePaginationPosition();
    }

    _initIcons() {
        if (this.level) {
            switch (true) {
                case this.level.innerText == 'Facile': {
                    this.chef2.classList.add(this._elMod('sumIconChef', 'desel'));
                    this.chef3.classList.add(this._elMod('sumIconChef', 'desel'));
                    break;
                }
                case this.level.innerText == 'Media' || this.level.innerText == 'Medio': {
                    this.chef3.classList.add(this._elMod('sumIconChef', 'desel'));
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }

    _randomizeCards() {
        shuffleCards(this.track);
        this.track.classList.remove(this._elMod('track', 'invisible'));
    }

    async _initSwiper() {
        const slideCounts = this._dEl('slideContainer', true).length;
        if (this.pagination && slideCounts <= 1) {
            this.pagination.classList.add(this._elMod('pagination', 'hidden'));
        }

        const options = {
            wrapperClass: this._el('track'), 
            slideClass: this._el('slideContainer') 
        };

        if (this.sliderAutoplayDelay >= 1) {
            options.autoplay = { delay: this.sliderAutoplayDelay * 1000, disableOnInteraction: false };
        }

        if (this.pagination) {
            options['pagination'] = {
                el: this._dEl('pagination', true),
                bulletClass: this._el('bullet'),
                bulletActiveClass: this._elMod('bullet', 'active'),
                clickable: true,
            };
        }

        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, options);
        this.swiper.on('slideChangeTransitionEnd', () => {
            this._updatePaginationPosition();
        });
    }

    _addEventListeners() {
        this._addListener(
            'resize',
            () => {
                this._updatePaginationPosition();
            },
            window
        );
    }

    _updatePaginationPosition() {
        if (window.innerWidth < BREAKPOINTS.l) {
            this.pagination.style.left = 0;
            return;
        }

        const swiperActive = this.root.querySelector('.swiper-slide-active [data-variant]');
        if (!swiperActive) return;

        const variant = swiperActive.dataset.variant;

        switch (variant) {
            case 'a': {
                const slideCounts = this._dEl('slideContainer', true).length;
                if (slideCounts <= 1) return;
                const cta = swiperActive.querySelector(this._el('cta', true));
                if (!cta) return;
                this.pagination.style.left = `${cta.getClientRects()[0].left}px`;
                this.pagination.classList.remove(this._elMod('pagination', 'hidden'));
                break;
            }
            default: {
                this.pagination.classList.add(this._elMod('pagination', 'hidden'));
            }
        }
    }
}


