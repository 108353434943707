import './style.scss';
import Component from '../../../../../libs/components/component';
import { getExtApiHelper } from '../../../../../libs/ext-api-helper'; 
import { shuffleCards } from '../../../../../libs/utils';

export default class BannerCarousel extends Component {
    constructor(name, root) {
        super(name, root);
        this.carousel = this._dEl('carousel');
        this.track = this._dEl('track');
        this.slides = this._dEl('slideContainer', true);
        if (!this.slides || this.slides.length <= 0) {
            
            this._removeMainComponent();
            return;
        }

        if (root.dataset.randomizeCards == 'true') {
            this._randomizeCards();
        }
        this.sliderAutoplayDelay = Number(root.dataset.sliderAutoplayDelay); 
        this.pagination = this._dEl('pagination');

        this.swiper = null;
        this._initSwiper();
    }

    _randomizeCards() {
        shuffleCards(this.track); 
        this.track.classList.remove(this._elMod('track', 'invisible')); 
    }

    async _initSwiper() {
        if (this.pagination && this.slides.length > 1) {
            this.pagination.classList.remove(this._elMod('pagination', 'hidden'));
        }

        const options = {
            wrapperClass: this._el('track'),
            slideClass: this._el('slideContainer'),
            slidesPerView: 'auto',
            spaceBetween: 16,
            watchOverflow: true,
            observer: true,
            observeParents: true,
        };
        if (this.pagination) {
            options.pagination = {
                el: this._dEl('pagination'),
                bulletClass: this._el('bullet'),
                bulletActiveClass: this._elMod('bullet', 'active'),
                clickable: true,
            };
            options.mousewheel = {
                eventsTarget: this.carousel,
                releaseOnEdges: true,
            };
        }
        if (this.sliderAutoplayDelay >= 1) {
            options.autoplay = { delay: this.sliderAutoplayDelay * 1000, disableOnInteraction: false };
        } 


        const Swiper = await getExtApiHelper().getSwiper();
        this.swiper = new Swiper(this.carousel, options);
        this.swiper.on('activeIndexChange', (swiper) => {
            this._setInactiveBulletsBgColor(swiper.activeIndex);
        });

        if (!this.root.dataset.bulletsBgColor) return;
        this.bulletsBgColor = JSON.parse(this.root.dataset.bulletsBgColor);
        this.bullets = this._dEl('bullet', true);
        this._setInactiveBulletsBgColor(0);
    }

    _setInactiveBulletsBgColor(activeIndex) {
        for (let i = 0; i < this.bullets.length; i++) {
            if (i == activeIndex) continue;
            this.bullets[i].style.backgroundColor = this.bulletsBgColor[activeIndex];
        }
    }

    /* override */
    dispose() {
        super.dispose();
        this.swiper?.destroy(true, true);
    }
}


