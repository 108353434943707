import { getDictionary } from './dictionary-provider';
import { runTemplate } from './htl-runtime/HTMLRuntime';
import { getRegister } from './register';
import * as dNextTick from 'next-tick';
import { getApiProvider } from './api-provider';

///////////////////////////////////////////////////
//////////// PROMISIFY EVENT
///////////////////////////////////////////////////
export const buildWaitForEvent = (eventName) => (node, func) =>
    new Promise((resolve, reject) => {
        // reject for invalid node
        if (!(node instanceof window.HTMLElement || node instanceof window.SVGElement)) {
            return reject(new Error('tail-end: an HTML or SVG element is required.'));
        }

        // create the event handler
        const handler = () => {
            // unbind the handler
            node.removeEventListener(eventName, handler);
            // resolve the (now clean) node
            return resolve(node);
        };

        // bind the handler
        node.addEventListener(eventName, handler);

        // if it exists, call the function passing in the node
        if (typeof func === 'function') {
            window.requestAnimationFrame(() => func(node));
        }
    });

///////////////////////////////////////////////////
//////////// PROMISIFY ANIMATIONEND - TRANSITIONEND
///////////////////////////////////////////////////
export const animationEnd = buildWaitForEvent('animationend');
export const transitionEnd = buildWaitForEvent('transitionend');

///////////////////////////////////////////////////
//////////// DELAY
///////////////////////////////////////////////////
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const nextTick = () => {
    return new Promise((resolve) => dNextTick(resolve));
};

///////////////////////////////////////////////////
//////////// NEXT PREV SIBLINGS
///////////////////////////////////////////////////
export const getNextSibling = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
    }
};

export const getPrevSibling = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.previousElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
    }
};

export const getNextSiblingAll = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;
    let result = [];
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (selector) {
            if (sibling.matches && sibling.matches(selector)) result.push(sibling);
        } else result.push(sibling);
        sibling = sibling.nextElementSibling;
    }
    return result;
};

export const getPrevSiblingAll = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.previousElementSibling;
    let result = [];
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (selector) {
            if (sibling.matches && sibling.matches(selector)) result.push(sibling);
        } else result.push(sibling);
        sibling = sibling.previousElementSibling;
    }
    return result;
};

///////////////////////////////////////////////////
//////////// FIRST AND LAST CHILD
///////////////////////////////////////////////////
export const getFirstChild = function (elem, selector) {
    // Get the first child element
    let nodes = elem.children;
    if (nodes.length <= 0) return;

    // If there's no selector, return the first sibling
    if (!selector) return nodes[0];

    // If the child node matches our selector, use it
    // If not, jump to the next child and continue the loop
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].matches && nodes[i].matches(selector)) return nodes[i];
    }
};

export const getLastChild = function (elem, selector) {
    // Get the last child element
    let nodes = elem.children;
    if (nodes.length <= 0) return;

    // If there's no selector, return the last sibling
    if (!selector) return nodes[nodes.length - 1];

    // If the child node matches our selector, use it
    // If not, jump to the prev child and continue the loop
    for (let i = nodes.length - 1; i >= 0; i--) {
        if (nodes[i].matches && nodes[i].matches(selector)) return nodes[i];
    }
};

export const getClosestAll = function (elem, selector) {
    const closestOne = (el, arr) => {
        if (!el) return arr;

        const clo = el.closest(selector);
        if (!clo) return arr;

        return closestOne(clo.parentElement, [clo, ...arr]);
    };

    return closestOne(elem, []);
};

/**
 * Check if a node is inside the body
 * @param {*} node
 * @returns
 */
export const isInPage = function (node) {
    return node === document.body ? false : document.body.contains(node);
};

///////////////////////////////////////////////////
//////////// CREATE QUERY PARAMETERS
///////////////////////////////////////////////////
export const buildQuery = (obj) =>
    Object.entries(obj)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');

///////////////////////////////////////////////////
//////////// HTML ELEMENTS
///////////////////////////////////////////////////
export const htmlToElement = (html) => {
    let template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
};

export const emptyElement = (element) => {
    while (element.firstChild) {
        element.removeChild(element.lastChild);
    }
};

///////////////////////////////////////////////////
//////////// OTHERS
///////////////////////////////////////////////////
export const isPositive = (value) => {
    if (value == true || value == 'true' || value == 'S' || value == 's' || value == '1') {
        return true;
    }
    return false;
};

/**
 * Check if an object is null or empty
 * @param {*} obj
 * @returns
 */
export const isObjectEmpty = (obj) => {
    return !obj || (Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype);
};

///////////////////////////////////////////////////
//////////// DEBOUNCE
///////////////////////////////////////////////////
export const debounce = (cb, delay = 1000) => {
    let timeoutId = null;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            cb.apply(null, args);
        }, delay);
    };
};

///////////////////////////////////////////////////
//////////// MAKE ID
///////////////////////////////////////////////////
export const makeid = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

///////////////////////////////////////////////////
//////////// TIME INSTANT
///////////////////////////////////////////////////
export const timeInstant = (formattedHour) => {
    let t0 = new Date();
    let t0split = formattedHour.split(':');
    t0.setHours(parseInt(t0split[0]));
    t0.setMinutes(parseInt(t0split[1]));
    return t0;
};

/**
 *
 * @param {String} text
 * @returns Convert a text to url friendly
 */
export const toUrlFriendly = (text) => {
    let result = text
        .normalize('NFD')
        .trim()
        .toLowerCase()
        .replaceAll(/\s|[//_:]/g, '-')
        .replaceAll('[^a-z0-9-]', '');
    return result.length > 150 ? result.substring(0, 150) : result;
};

/**
 *
 * @returns true if referrer comes from current domain
 */
export const isReferrerCurrentDomain = () => {
    if (!document.referrer) return false;
    try {
        const referrer = new URL(document.referrer);
        return referrer.origin === location.origin;
    } catch (e) {
        console.info('Cannot check referrer');
    }
    return false;
};

export const isInViewport = function (el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

///////////////////////////////////////////////////
//////////// CALCULATE OVERLAY POSITION
///////////////////////////////////////////////////
export const calcTop = (self, target, offset = 0) => {
    const rect = target.getBoundingClientRect();
    const popHeight = self.clientHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let top = rect.top + scrollTop + rect.height + 8;
    if (top + popHeight > document.documentElement.scrollHeight) {
        const diff = top + popHeight - document.documentElement.scrollHeight;
        top = top - diff - 8;
        top = top >= 0 ? top : 0;
    }
    return top + offset + 'px';
};

export const calcLeft = (self, target, offset = 0) => {
    const rect = target.getBoundingClientRect();
    const popWidth = self.clientWidth;
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    let left = rect.left + scrollLeft;
    if (left + popWidth > document.documentElement.scrollWidth) {
        const diff = left + popWidth - document.documentElement.scrollWidth;
        left = left - diff - 8;
        left = left >= 0 ? left : 0;
    }
    return left + offset + 'px';
};

///////////////////////////////////////////////////
//////////// FLYERS
///////////////////////////////////////////////////
export const flyerData = (data) => {
    const dictionary = getDictionary();
    const flyer = { ...data };

    const computeFlyerValidity = (flyer) => {
        const fullDateFormatter = new Intl.DateTimeFormat('it-IT', { day: 'numeric', month: 'short', year: 'numeric' });
        const shortDateFormatter = new Intl.DateTimeFormat('it-IT', { day: 'numeric', month: 'short' });
        const dayFormatter = new Intl.DateTimeFormat('it-IT', { day: 'numeric' });
        const from = dictionary.get('From the');
        const to = dictionary.get('to the');
        const until = dictionary.get('until');
        if (!flyer.validFrom && !flyer.validTo) {
            return dictionary.get('always valid');
        }
        if (!flyer.validFrom && flyer.validTo) {
            let eDate = new Date(flyer.validTo);
            return `${until} ${fullDateFormatter.format(eDate)}`;
        }
        if (!flyer.validTo && flyer.validFrom) {
            let sDate = new Date(flyer.validFrom);
            return `${from} ${fullDateFormatter.format(sDate)}`;
        }
        let sDate = new Date(flyer.validFrom);
        let eDate = new Date(flyer.validTo);
        if (sDate.getFullYear() !== eDate.getFullYear()) {
            return `${from} ${fullDateFormatter.format(sDate)} ${to} ${fullDateFormatter.format(eDate)}`;
        }
        if (sDate.getMonth() !== eDate.getMonth()) {
            return `${from} ${shortDateFormatter.format(sDate)} ${to} ${fullDateFormatter.format(eDate)}`;
        }
        return `${from} ${dayFormatter.format(sDate)} ${to} ${fullDateFormatter.format(eDate)}`;
    };

    flyer.validity = computeFlyerValidity(flyer);
    flyer.downloadLink = {
        href: flyer.pdfUrl,
        target: '_blank',
        download: '',
    };
    flyer.linkLabel = dictionary.get('Watch the flyer');
    if (flyer.link) {
        flyer.link = {
            target: '_blank',
            ...flyer.link,
        };
    }
    return flyer;
};

export const flyersData = (store) => {
    let rawData = store.volantini || [];
    let result = [];
    for (let flyer of rawData) {
        result.push(flyerData(flyer));
    }
    return result;
};

///////////////////////////////////////////////////
//////////// GENERATE SHARE LINKS TOOLTIP
///////////////////////////////////////////////////
export const generateShareLinks = async (href, title, ignoreSharers = [], extraClasses = null) => {
    const id = makeid(10);
    const shareLinksData = {
        uniqueId: id,
        extraClasses: extraClasses,
        heading: `${getDictionary().get('Share on:')}`,
        shareUrl: href,
        shareTitle: title,
        ignoreSharers: ignoreSharers,
    };
    const shareLinksHtml = (await import(
        '../repository/apps/conad-corporate/templates/rt029-share-links/rt029-share-links.html'
    )).default;
    const shareLinksTooltip = runTemplate(shareLinksHtml, shareLinksData);

    const mainPage = document.body.querySelector('.rs1-page');
    mainPage.appendChild(shareLinksTooltip);

    return id;
};

///////////////////////////////////////////////////
//////////// GENERATE TOOLTIP
///////////////////////////////////////////////////
export const generateTooltip = async (text, extraClasses = null) => {
    const id = makeid(10);
    const tooltipData = {
        uniqueId: id,
        extraClasses: extraClasses,
        text: text,
    };

    const tooltipHtml = (await import('../repository/apps/conad-corporate/templates/rt035-tooltip/rt035-tooltip.html'))
        .default;
    const tooltip = runTemplate(tooltipHtml, tooltipData);

    const mainPage = document.body.querySelector('.rs1-page');
    mainPage.appendChild(tooltip);

    return id;
};

///////////////////////////////////////////////////
//////////// DEEP MERGE
///////////////////////////////////////////////////
export const isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
};

export const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, {
                        [key]: {},
                    });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {
                    [key]: source[key],
                });
            }
        }
    }

    return mergeDeep(target, ...sources);
};

///////////////////////////////////////////////////
//////////// LOADER
///////////////////////////////////////////////////

export const openLoader = (name) => {
    const loader = document.getElementById(`loader-${name}`);
    if (!loader) return;
    const loaderObj = getRegister().getClass(loader);
    if (!loaderObj) return;
    loaderObj?.open();
};

export const closeLoader = (name) => {
    const loader = document.getElementById(`loader-${name}`);
    if (!loader) return;
    const loaderObj = getRegister().getClass(loader);
    loaderObj?.close();
};

export const isLoaderOpen = (name) => {
    const loader = document.getElementById(`loader-${name}`);
    if (!loader) return;
    const loaderObj = getRegister().getClass(loader);
    return loaderObj?.isOpen();
};

///////////////////////////////////////////////////
//////////// GENERATE FEEDBACK
///////////////////////////////////////////////////
export const generateFeedback = async ({ extraClasses, text, semanticType, ctaLabel, ctaLink, unclosable = false }) => {
    const feedbackHtml = (await import(
        '../repository/apps/conad-corporate/templates/rt036-feedback/rt036-feedback.html'
    )).default;
    const feedback = runTemplate(feedbackHtml, {
        uniqueId: makeid(10),
        extraClasses: extraClasses,
        text: text,
        semanticType: `${semanticType}` /* 1 (green), 2 (red), 3 (yellow), 4 (blue) */,
        ctaLabel: ctaLabel,
        ctaLink: ctaLink,
        unclosable: unclosable,
    });

    const feedbackContainer = document.getElementById('feedback-container');
    feedbackContainer?.appendChild(feedback);

    return;
};

///////////////////////////////////////////////////
//////////// TRACKING COMMONS
///////////////////////////////////////////////////

export const generateTrackVolantinoInfo = (flyer = null) => {
    return {
        idVolantino: flyer?.id || 'data.flyerId',
        CooperativaVolantino: flyer?.cooperative || 'data.flyerCoop',
        nomeVolantino: flyer?.name || 'data.flyerName',
        TipologiaVolantino: flyer?.hasDisaggregated ? 'Disaggregato' : 'Sfogliatore' || 'data.flyerType',
    };
};

export const generateTrackNegozioInfo = (store = null, withServices = false) => {
    const trackData = {
        idNegozio: store?.anacanId || 'data.storeId',
        CooperativaNegozio: store?.codiceCooperativa || 'data.coopId',
        Insegna: store?.descrizioneInsegna || 'data.insignia',
    };
    if (withServices) {
        trackData.ServiziAggiuntivi = 'vars.currentServices';
    }
    return trackData;
};

///////////////////////////////////////////////////
//////////// ARRAY
///////////////////////////////////////////////////
export const shuffleArray = (array) => {
    const newArray = [...array];
    const length = newArray.length;

    for (let start = 0; start < length; start++) {
        const randomPosition = Math.floor((newArray.length - start) * Math.random());
        const randomItem = newArray.splice(randomPosition, 1);

        newArray.push(...randomItem);
    }

    return newArray;
};

///////////////////////////////////////////////////
//////////// COOKIES
///////////////////////////////////////////////////
export const getCookieByName = (name) => {
    let mt = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    if (mt) return mt.pop();
    return '';
};

export const writeCookieWithName = (name, value, domain, expires = undefined) => {
    document.cookie = `${name}=${value};domain=${domain};path=/;${expires ? 'expires=' + expires : ''}`;
};

///////////////////////////////////////////////////
//////////// ASYNC REPLACE
///////////////////////////////////////////////////
/**
 * Replace async
 * @param {*} string
 * @param {*} regexp
 * @param {*} replacerFunction
 * @returns
 */
export const replaceAsync = async (string, regexp, replacerFunction) => {
    const replacements = await Promise.all(Array.from(string.matchAll(regexp), (match) => replacerFunction(...match)));
    let i = 0;
    return string.replace(regexp, () => replacements[i++]);
};

///////////////////////////////////////////////////
//////////// LOAD STEP - MODAL POPUP
///////////////////////////////////////////////////
export const loadStep = async (stepName) => {
    const el = document.querySelector(`[data-name=${stepName}]`);
    if (el) {
        await nextTick();
        return el;
    }

    let found = null;
    const availableSteps = [...window.popups, ...window.modals];
    for (const step of availableSteps) {
        if (step.name == stepName) {
            found = step;
            break;
        }
    }

    if (!found) throw 'Cannot find step ' + stepName;
    const html = await getApiProvider().loaderGet(found.url);
    const parser = new DOMParser();
    const parsed = parser.parseFromString(html, 'text/html');
    const result = document.getElementById('mp-container').appendChild(parsed.body.firstElementChild);
    forceReflow(); // necessary to run transitions on element
    return result;
};

/**
 * Forces restyle and reflow
 * @returns
 */
export const forceReflow = () => {
    return document.body.offsetWidth;
};

/**
 * Get the Z-Index of an element
 * @param {HTMLElement} e
 * @returns
 */
export const getZIndex = function (e) {
    const z = window.getComputedStyle(e).getPropertyValue('z-index');
    if (isNaN(z)) return getZIndex(e.parentNode);
    return z;
};

///////////////////////////////////////////////////
//////////// FORM CHECK WITHOUT TRIGGER VALIDATION
///////////////////////////////////////////////////
export function checkFormWithoutValidation(form) {
    const inputsInvalid = form.querySelector('input:invalid');
    if (inputsInvalid) {
        return false;
    } else {
        return true;
    }
}

///////////////////////////////////////////////////
//////////// SHUFFLE CARDS 
/////////////////////////////////////////////////// 
export function shuffleCards(cardsContainer) {
    for (let i = cardsContainer.children.length; i > -1; i--) {
        cardsContainer.appendChild(cardsContainer.children[Math.floor(Math.random() * i)]);
    }
}


